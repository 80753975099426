/* eslint-disable react/jsx-props-no-spreading */
import querystring from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { rewardsv2Enabled } from '../../helpers/features';

// Utility function to prefetch an image.
function prefetchImage(url) {
  const img = new Image();
  img.src = url;
}

export default function preventAuthenticatedUser(ComposedComponent) {
  class AuthRoute extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    static getDerivedStateFromProps(props) {
      if (props.user.authenticated) {
        const queries = querystring.parse(props.history.location.search);
        const { redirect, ...params } = queries;
        if (redirect === '/rewards') {
          // Prefetch the rewards guide image so it is cached when the rewards page loads.
          prefetchImage('/images/rewards_rg_pay_guide.gif');
          params.show_rg_pay_modal = true;
        }
        if (redirect) {
          const search = querystring.stringify(params);
          props.history.push({
            pathname: redirect,
            search: search ? `?${search}` : '',
            state: props.location.state,
          });
        } else if (rewardsv2Enabled()) {
          props.history.push('/rewards?show_rg_pay_modal=true');
        } else {
          props.history.push('/properties');
        }

        return null;
      }
      return props;
    }

    render() {
      return <ComposedComponent {...this.props} {...this.state} />;
    }
  }

  const mapStateToProps = ({ user }) => ({ user });
  return withRouter(connect(mapStateToProps, null)(AuthRoute));
}
